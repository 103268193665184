import {Component, Injectable, OnInit} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {HTMLInputEvent} from '../../instructions/models/html-input-event';
import {Activity} from '../../instructions/enums/users';
import {OrderingService} from './ordering.service';
import {BinderComponentsService} from '../../instructions/services/binder-components.service';

// import {BinderComponentsService} from '../../instructions/services/binder-components.service';

@Component({
  selector: 'app-ordering',
  templateUrl: './ordering.component.html',
})
@Injectable()
export class OrderingComponent implements OnInit {
  private  activity: any;
  private cartArrayStringify: string;

  constructor(
    private orderingService: OrderingService,
    private notifier: NotifierService,
    private binderComponentsService: BinderComponentsService,
  ) {
    this.activity = Activity;
    this.cartArrayStringify = localStorage.getItem(this.activity.userCart);
  }

  public ngOnInit(): void {
  }
  public onSubmit(form: NgForm, event: HTMLInputEvent): void {
    const userOrdering  = form.value;
    const jsonCert = JSON.parse(this.cartArrayStringify);
    const dataOrdering = {cart: jsonCert, userOrdering }; // Интерфейс
    const stringify  = JSON.stringify(dataOrdering);
    const formData = new FormData();
    formData.append('orders', stringify);
    this.orderingService.setOrdering(formData).subscribe((data: any) => {
      const settingsUser = {ordering: false};
      settingsUser.ordering = data[0] === 'success';
      if (settingsUser.ordering) {
        this.notifier.notify( 'success', 'Ваш заказ принят!');
      }
      const value = JSON.stringify(settingsUser);
      localStorage.setItem(this.activity.confirmOrder, value);
      this.binderComponentsService.initOrderingConfirm(settingsUser);
    });
  }
}
