import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Tools} from '../../constructions/tools';
import {ImpressionModel} from '../../instructions/models/impression.model';

@Injectable()
export class OrderingService {
  // public options: RequestOptions;
  private tools: Tools;
  constructor(public http: HttpClient) {
    this.tools = new Tools();
  }

  public setOrdering(data: FormData): Observable<ImpressionModel[]> {
    return this.http.post<any>(`${this.tools.pathSite}/setUser/ordering`, data);
  }

}
