import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Tools} from '../../constructions/tools';
import {User} from '../models/user.model';
import {HttpServer} from '../../http/server';

@Injectable()
export class UsersService {
    private tools: Tools;
    constructor(public http: HttpClient, private httpServer: HttpServer) {
        this.tools = new Tools();
    }
    public checkEmail(email: string): Observable<any> {
        return this.http.post(`${this.tools.pathSite}/users/email`, email);
    }
    public getUserByEmail(): Observable<any> {
        return this.http.get(`${this.tools.pathSite}/users/get`);
    }
    public getUserData($data: User[]): Observable<{}> {
        return this.http.post(`${this.tools.pathSite}/users/get`, $data);
    }
    public createNewUser(user: User): Observable<any> {
        return this.http.post(`${this.tools.pathSite}/users/add`, user);
    }

    public checkPassword(pass: User): Observable<any> {
        return this.http.post(`${this.tools.pathSite}/users/password`, pass);
    }
    // public checkAuth(token): Observable<any> {
    //     return this.http.get(`${this.tools.pathSite}/users/auth`, {
    //       headers : new HttpHeaders({
    //         Authorization: token})});
    // }
  public checkAuth(): Observable<any> {
      return this.httpServer.getRequest({route: '/users/auth'});
  }
}
