import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Tools} from '../constructions/tools';

@Injectable()
export class RefreshService {
  private tools: Tools;
  constructor(public http: HttpClient) {
    this.tools = new Tools();
  }

  public tokenRefresh(token): Observable<any> {
    return this.http.get(`${this.tools.pathSite}/token/refresh`, {
      headers : new HttpHeaders({
        Authorization: token})});
  }
}
