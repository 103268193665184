import { Action } from '@ngrx/store';

export enum loaderActionsType {
  updatedAt = '[Loader] updated atLoader',
}

export class LoaderAction implements Action {
  public readonly type = loaderActionsType.updatedAt;
  constructor(public payload: boolean) {}
}
export type LoaderActions = LoaderAction;
