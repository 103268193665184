import {Component, OnInit, Input, Injectable, OnDestroy, ViewChild} from '@angular/core';
import {UsersService} from '../instructions/services/users.service';
import {User} from '../instructions/models/user.model';
import {Subscription} from 'rxjs';
import {Auth} from '../instructions/models/auth.model';
import {AdminAuthService} from '../instructions/services/admin.auth.service';
import { Router } from '@angular/router';
// import {CategoryService} from './category.service';
// import {CategoryModel} from '../model/category.model';
import { Location } from '@angular/common';
import {CommentsComponent} from '../user/comments/comments.component';
import {BinderComponentsService} from '../instructions/services/binder-components.service';

// import {CommentsComponent} from '../user/comments/comments.component';
// import {CommentsService} from '../user/comments/comments.service';
@Component({
    selector: 'auth',
    templateUrl: './auth.component.html',
})

@Injectable()
export class AuthComponent implements OnInit, OnDestroy {
    public dataServerCategory: any;
    public login: boolean;
    public userData: User; // Auth |
    public switchRegistrationUser: boolean;
    public admin: boolean;
    public user: boolean;
    // @Output() public authAdmin = new EventEmitter<any>();
    // @ViewChild(CommentsComponent) public commentsComponent: CommentsComponent;
    private sub1: Subscription;
    constructor(
      private usersService: UsersService,
      private adminAuthService: AdminAuthService,
      private router: Router,
      private binderComponentsService: BinderComponentsService,
      // private commentsComponent: CommentsComponent,
      // private location: Location,
      // private route: ActivatedRoute,
      // private commentsService: CommentsService,
    ) {
        this.dataServerCategory = 0;
        this.login = false;
        this.switchRegistrationUser = false;
        this.admin = false;
        this.user = false;
    }
    public ngOnInit(): void {
      this.authorized(''); // param empty for API
    }
    public authUser(data: any): void { // param data for API
      this.authorized(data);
    }
    public authorized(data, admin: boolean = false ): void { // param dataUser set cookies for API
        const token = localStorage.getItem('token');
        if (token) {
          this.sub1 = this.usersService.checkAuth() // dataUser// сделать store
            .subscribe((login: User) => {
              this.adminAuthService.setUserData(login);
              if (!!login) {
                this.userData = login;
                this.login = !!login.id;
              }
              this.binderComponentsService.init(this.login);
              if (admin) {
                if (typeof login.type !== 'undefined') {
                  if (login.type !== 'admin') {
                    // this.authAdmin.emit(true);
                    this.redirect();
                  }
                } else {
                  this.redirect();
                }
              }
            });
        }
    }
    public redirect(user: boolean = false): void {
      if (user) {
        return;
      }
      this.router.navigate(['/'], {
        queryParams: {
          accessDenied: true,
        },
      });
    }
    public ngOnDestroy(): void {
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
    }
    public registrationUser(): void {
        this.switchRegistrationUser = true;
    }
    public loginUser(): void {
        this.switchRegistrationUser = false;
    }
    public exitLogin(): void {
      // this.clearCookie('login_email');
      // this.clearCookie('login_password');
      localStorage.removeItem('token');
      this.login = false;
      this.userData = null;
      // this.commentsComponent.checkAuth(this.login);
      this.binderComponentsService.init(this.login);
      this.adminAuthService.setUserData({});
    }
    private getCookie(name): any {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }
    private clearCookie(name): void {
    // This function will attempt to remove a cookie from all paths.
    const pathBits = location.pathname.split('/');
    let pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';
    let i = 0;
    for (const item = 0; i < pathBits.length;) {
      pathCurrent += ((pathCurrent.substr(-1) !== '/') ? '/' : '') + pathBits[i];
      document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
      i++;
    }
  }
}
