import {routerNameActionsType, RoutesActions} from '../actions/routers.actions';

export const routersReducer = (state: {name: ''}, action: RoutesActions) => {

  switch (action.type) {
    case routerNameActionsType.updatedAt:
      return {
        ...state,
        name: action.payload,
      };
    default:
      return state;
  }
};
