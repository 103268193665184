import { Component, OnInit } from '@angular/core';
import {animate, group, state, style, transition, trigger} from '@angular/animations';
import {BinderComponentsService} from '../instructions/services/binder-components.service';
import {InterfaceModalSettings} from '../instructions/models/interface.modal.settings';

@Component({
  animations: [
    trigger('openClose', [
      state('open', style({  opacity: '1' })),
      state('closed', style({ opacity: '0', visibility: 'hidden' })),
       transition('closed <=> open', animate(175)),
     // transition('* => block', animate(5000)),
    ]),
  ],
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  public showModal: string;
  public showComponent: boolean;
  public settings: InterfaceModalSettings;
  public styleModal: {};

  constructor(private binderComponentsService: BinderComponentsService) {
    this.showModal = 'closed';
    this.showComponent = false;
    const open = false;
    this.settings = {open, width: 1024, height: 900};
  }

  public ngOnInit(): void {
    this.binderComponentsService.ordering.subscribe((settingsModal: InterfaceModalSettings) => {
      this.settings = settingsModal;
      if (this.settings.open) {
        this.showModal = 'open';
      } else {
        this.showModal = 'closed';
      }

      this.styleModal = {
        height: this.settings.height + 'px',
        width: this.settings.width + 'px',
      };
    });
  }
  public closeModal(open): void {
    this.binderComponentsService.initOrdering(open);
  }

}
