import {EventEmitter, Output} from '@angular/core';
import {InterfaceModalSettings} from '../models/interface.modal.settings';

export class BinderComponentsService {
  private clickCnt: number;
  public loadData: EventEmitter<boolean> = new EventEmitter();
  public addInCard: EventEmitter<number> = new EventEmitter();
  public deleteInCard: EventEmitter<number> = new EventEmitter<any>();
  public ordering: EventEmitter<InterfaceModalSettings> = new EventEmitter<any>();
  public orderingConfirm: EventEmitter<{}> = new EventEmitter<any>();
  public receivingConfirm: EventEmitter<{}> = new EventEmitter<any>();
  public event: EventEmitter<{}> = new EventEmitter<any>();
  public home: EventEmitter<{}> = new EventEmitter<any>();
  // public addInCard: EventEmitter<any> = new EventEmitter();
  constructor() {
    this.clickCnt = 0;
  }

  public init(login: boolean): void {
    this.loadData.emit(login);
  }

  public initCard(articleId: number): void {
    this.addInCard.emit(articleId);
  }

  public initCardArticle(articleId: number): void {
    this.deleteInCard.emit(articleId);
  }

  public initOrdering(settingsModal: InterfaceModalSettings): void {
    this.ordering.emit(settingsModal);
  }

  public initOrderingConfirm(settingsUser: {}): void {
    this.orderingConfirm.emit(settingsUser);
  }

  public initReceivingConfirm(settingsUser: {} = {}): void {
    this.receivingConfirm.emit(settingsUser);
  }
  public initEvent(eventParams: {} = {}): void {
    this.event.emit(eventParams);
  }
  public initHome(): void {
    this.home.emit();
  }
}
