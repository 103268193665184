import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        // NgxChartsModule
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
       // NgxChartsModule
        // LoaderComponent
    ],
})
export class InstructionsModules {}
