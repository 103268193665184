import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {UsersService} from '../../instructions/services/users.service';
import {User} from '../../instructions/models/user.model';
import {fadeStateTrigger} from '../../constructions/animations/fade.animations';
import {Message} from '../../instructions/models/massage.model';
import {Tools} from '../../constructions/tools';


@Component({
  animations: [fadeStateTrigger],
  selector: 'login',
  templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit {

    public form: FormGroup;
    public message: Message;
    @Output() public authUser = new EventEmitter<any>();
    private tools: Tools;

    constructor(
        private usersService: UsersService,
        private title: Title,
        private meta: Meta,
        private router: Router,
    ) {
        title.setTitle('Вход в систему');
        this.tools = new Tools();
    }

    public ngOnInit(): void {
        this.message = new Message('danger', '');
        this.form = new FormGroup({
            email: new FormControl(null, [Validators.required], this.correctEmail.bind(this)), // Validators.email
            password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        });
        this.authUser.emit({});
    }
    public onSubmit(): void {
        const formData = this.form;
        // const formValue = structuredClone(formData.value);
        const formValue = JSON.parse(JSON.stringify(formData.value));
        formValue.password = this.tools.encryptApi(formValue.password);
        this.usersService.getUserData(formValue)
            .subscribe((user: User) => {
                if (!user.token) {
                    this.showMessage({text: 'Неверный логин или пароль', type: 'danger'});
                } else {
                  localStorage.setItem('token', user.token);
                  this.authUser.emit(user.token);
                  this.router.navigate(['admin']);
                }
            });
    }

    public correctEmail(control: FormControl): Promise<any> {
        return new Promise((resolve, reject) => {
            this.usersService.checkEmail(control.value)
                .subscribe((email: any) => {
                    if (email.correct !== 'yes') {
                        resolve({anCorrectEmail: true});
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    private showMessage(message: Message): void {
        this.message = message;
        window.setTimeout(() => {
            this.message.text = '';
        }, 5000);
    }
}
