import {LoaderActions, loaderActionsType} from '../actions/loader.actions';

export const loaderReducer = (state: {loader: false}, action: LoaderActions ) => {

  switch (action.type) {
    case loaderActionsType.updatedAt:
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};
