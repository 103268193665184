import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { from } from 'rxjs';
// import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {ArticlesModule} from './app/articles.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(ArticlesModule);
