import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Tools} from '../../constructions/tools';

import {ImpressionModel} from '../../instructions/models/impression.model';

@Injectable()
export class CartService {
  // public options: RequestOptions;
  private tools: Tools;
  constructor(public http: HttpClient) {
    this.tools = new Tools();
  }

  public cartArticles(data: FormData): Observable<ImpressionModel[]> { // <MenuPagesModel[]> // нужен интерфейс для комментария
    return this.http.post<any>(`${this.tools.pathSite}/getArticleCart/definiteList`, data);
  }

}
