import {countActionsType, MethodsActions} from './user-categories.actions.methods';

export const userCategoriesMethodsReducer = (state: any = 'qwe', action: MethodsActions) => {

  switch (action.type) {
    case countActionsType.updatedAt:
      return state = action.payload;
    case countActionsType.updatedAtName:
      return state = action.payload;
    default:
      return state;
  }
};
