import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import {SelectComponent} from '../components/form/select.component';
// import {DatepickerDirective} from '../components/form/datepicker.directive';
// import {TimePickerDirective} from '../components/form/time.directive';
// import {MultiSelectComponent} from '../components/form/multiselect.component';
// import {DateTimePickerDirective} from '../components/form/datetime.directive';
import {FormsModule} from '@angular/forms';
import {SystemRoutingModule} from './system-routing.module';
import {SystemComponent} from './system.component';

import {AuthComponent} from './auth/auth.component';
import {RegistrationComponent} from './auth/registration/registration.component';
import {UsersService} from './instructions/services/users.service';
import {InstructionsModules} from './instructions/instructions.modules';
import {LoginComponent} from './auth/login/login.component';
import {AdminAuthService} from './instructions/services/admin.auth.service';
import {UserModule} from './user/user.module';
import {AdminModule} from './admin/admin.module';
import {ControlSettingsFilterComponent} from './control-settings-filter/control-settings-filter.component';
import {BinderComponentsService} from './instructions/services/binder-components.service';
import {NotificationComponent} from './notification/notification.component';
import {NotifierModule, NotifierOptions} from 'angular-notifier';
import {CartArticleComponent} from './user/cart/cart-article/cart.article.component';
import {CartService} from './user/cart/cart.service';
import {ModalComponent} from './modal/modal.component';
import {OrderingComponent} from './user/ordering/ordering.component';
import {OrderingService} from './user/ordering/ordering.service';
import {ToolToken} from './refresh/tool-token';
import {RefreshService} from './refresh/refresh.service';
import {HttpServer} from './http/server';
import {LoaderComponent} from './loader/loader.component';
import {StoreModule} from '@ngrx/store';
import {loaderReducer} from './reducer/reducers/loader.reduser';
import {userCategoriesMethodsReducer} from './user/user-categories/user.categories.methods.reducer';
import {routersReducer} from './reducer/reducers/router.reduser';
// import {FiltersUserNamesComponent} from './control-settings-filter/filters-names/filters-user-names.component';
// import {CommentsComponent} from './user/comments/comments.component';
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        SystemRoutingModule,
        InstructionsModules,
        // UserModule,
        // AdminModule
        // UserPagesModule,
        NotifierModule.withConfig(customNotifierOptions),
        // StoreModule.forRoot(
        //   {
        //     loader: loaderReducer,
        //   },
        // ),
      StoreModule.forRoot({
          loader: loaderReducer,
          methods: userCategoriesMethodsReducer,
          name: routersReducer,
        },
      ),
    ],
    declarations: [
        // SelectComponent,
        // TimePickerDirective,
        // MultiSelectComponent,
        // DateTimePickerDirective,
        // DatepickerDirective,
        SystemComponent,
        AuthComponent,
        RegistrationComponent,
        LoginComponent,
        // FiltersUserNamesComponent,
        // ControlSettingsFilterComponent
        NotificationComponent,
        CartArticleComponent,
        ModalComponent,
        OrderingComponent,
        LoaderComponent,
    ],
    providers: [
        SystemRoutingModule,
        UsersService,
        RegistrationComponent,
        AdminAuthService,
        ControlSettingsFilterComponent,
        // CommentsComponent,
        BinderComponentsService,
        CartService,
        OrderingService,
        ToolToken,
        RefreshService,
        HttpServer,
    ],
    bootstrap: [SystemComponent],
})

export class ArticlesModule {}
