import {authKey} from './constants/keys';
import AesCtr from './security/aesCtr';

export class Tools {
    public pathSite: string;
    public errorLoginUser: string;
    public errorLoginServer: string;
    constructor() {
        this.pathSite = 'https://rus-matrix.ru';
        this.errorLoginUser = 'Не верный логин или пароль';
        this.errorLoginServer = 'Ошибка сервера';
    }
    public converter(name: string): string {
        const converter: any = {
            'а': 'a',
            'б': 'b',
            'в': 'v',
            'г': 'g',
            'д': 'd',
            'е': 'e',
            'ё': 'yo',
            'ж': 'zh',
            'з': 'z',
            'и': 'i',
            'й': 'yi',
            'к': 'k',
            'л': 'l',
            'м': 'm',
            'н': 'n',
            'о': 'o',
            'п': 'p',
            'р': 'r',
            'с': 's',
            'т': 't',
            'у': 'u',
            'ф': 'f',
            'х': 'h',
            'ц': 'c',
            'ч': 'ch',
            'ш': 'sh',
            'щ': 'shch',
            'ь': 'j',
            'ы': 'y',
            'ъ': 'jj',
            'э': 'yoe',
            'ю': 'yu',
            'я': 'ja',
            'А': 'A',
            'Б': 'B',
            'В': 'V',
            'Г': 'G',
            'Д': 'D',
            'Е': 'E',
            'Ё': 'yo',
            'Ж': 'Zh',
            'З': 'Z',
            'И': 'I',
            'Й': 'YI',
            'К': 'K',
            'Л': 'L',
            'М': 'M',
            'Н': 'N',
            'О': 'O',
            'П': 'P',
            'Р': 'R',
            'С': 'S',
            'Т': 'T',
            'У': 'U',
            'Ф': 'F',
            'Х': 'H',
            'Ц': 'C',
            'Ч': 'Ch',
            'Ш': 'Sh',
            'Щ': 'SHCH',
            'Ь': 'J',
            'Ы': 'Y',
            'Ъ': 'JJ',
            'Э': 'YOE',
            'Ю': 'Yu',
            'Я': 'Ja',
            ' ': '-',
        };
        for (let i = 0; i < name.length; i++) {
            const f = name.charAt(i),
                r = converter[f];
            if (r) {
                name = name.replace(new RegExp(f, 'g'), r);
            }
        }
        return name;
    }

    public setCookie(cname: any, cvalue: any, exdays: any):void {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    public scroll(): void {
      if(window.innerWidth > 600) {
        window.addEventListener('scroll', () => {
          const container: any = document.querySelectorAll('.parallax-container');
          const imageNode: NodeList = <NodeList>document.querySelectorAll('[data-parallax]');
          const image: any = Array.from(imageNode);
          container.forEach((element, index) => {
            if (window.screen.availHeight
              < element.offsetTop && window.scrollY
              + window.screen.availHeight
              > element.offsetTop && window.scrollY
              < element.offsetTop + element.clientHeight) {
              if (image[index].height - element.clientHeight > 0) {
                const speed = window.screen.availHeight / (image[index].height - element.clientHeight);
                image[index].style.transform = `translateY(${(window.scrollY
                  + window.screen.availHeight - element.offsetTop) / speed}px)`;
              } else {
                const scale_value = element.clientHeight / image[index].height + 1;

                const speed = (window.screen.availHeight + element.clientHeight) / (element.clientHeight - image[index].height);
                image[index].style.transform = `translateY(${(window.scrollY
                  + window.screen.availHeight
                  - element.offsetTop) / speed}px) scale(${scale_value})`;
              }
            } else if (window.screen.availHeight > element.offsetTop && window.scrollY < element.offsetTop + element.clientHeight) {
              const speed = (element.offsetTop + element.clientHeight) / (image[index].height - element.clientHeight);
              image[index].style.transform = `translateY(${window.scrollY / speed}px)`;
            }
          });
        });
      }
    }

    public encryptApi(pass): string {

      return AesCtr.encrypt(pass, authKey, 256);
    }
}
