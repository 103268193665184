import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Tools} from '../constructions/tools';
import {InterfaceGetRequest} from '../instructions/models/interface/get.request';
import {InterfacePostRequest} from '../instructions/models/interface/post.request';

@Injectable()
export class HttpServer {
  private tools: Tools ;
  private token: string;
  constructor(public http: HttpClient) {
    this.tools = new Tools();
  }
  public postRequest(paramps: InterfacePostRequest ): Observable<any> {
    const token = localStorage.getItem('token');
    this.token = token ? token : 'error';
    return this.http.post(`${this.tools.pathSite}${paramps.route}`,
      paramps.payloadParams,
      {
        headers : new HttpHeaders({
          Authorization: this.token})},
    );
  }

  public getRequest(paramps: InterfaceGetRequest): Observable<any> {
    const token = localStorage.getItem('token');
    this.token = token ? token : 'error';
    return this.http.get(`${this.tools.pathSite}${paramps.route}`, {
      headers : new HttpHeaders({
        Authorization: this.token})});
  }
  public deleteRequest(paramps: InterfaceGetRequest): Observable<any> {
    const token = localStorage.getItem('token');
    this.token = token ? token : 'error';
    return this.http.delete(`${this.tools.pathSite}${paramps.route}`, {
      headers : new HttpHeaders({
        Authorization: this.token})});
  }
}
