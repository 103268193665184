import {
  Component,
  OnInit,
  Injectable,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterContentInit,
  AfterContentChecked,
  AfterViewInit, ChangeDetectorRef,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import 'rxjs/add/operator/mergeMap';

import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {FilterModel} from '../instructions/models/filter.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Tools} from '../constructions/tools';
@Component({
  selector: 'app-between-slider',
  templateUrl: './control-settings-filter.component.html',
})

@Injectable()
export class ControlSettingsFilterComponent implements OnInit, AfterViewInit {
  @Input() public filterMin: number;
  @Input() public sliderNumber: number;
  @Input() public arrayNameSlider: string;
  @Input() public filterUrlParams: any[];
  @ViewChild('tableToMeasure') public elementView;
  @ViewChild('controlCircle') public elementControlCircle;
  @Output() public fromToSum = new EventEmitter<any>();
  public positionSliderLeft: number;
  public positionSliderRight: number;
  public circleRadiusPercent: number;
  public widthSlider: number;
  public widthSliderStart: number;
  public leftSpace: number;
  public leftAmount: number;
  public rightAmount: number;
  public sumAmount: number;
  public amountAll: FilterModel;
  public settingsFilter: {};
  public min: string;
  public max: string;
  public constIndentCircle: number;
  private sliderActive: boolean;
  private leftActivateSlider: boolean;
  private rightActivateSlider: boolean;

  constructor(
    private route: ActivatedRoute,
    // private userArticlesSlider: UserArticlesSlider //
    private http: HttpClient,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.positionSliderLeft = 0;
    this.circleRadiusPercent = 0.016;
    // this.positionSliderRight = (1 - this.circleRadiusPercent) * 100;
    // this.widthSlider = (1 - this.circleRadiusPercent) * 100; .nativeElement.offsetWidth
    this.positionSliderRight =  100;
    this.widthSlider = 100;
    this.widthSliderStart = this.widthSlider;
    this.leftActivateSlider = false;
    this.rightActivateSlider = false;
    // this.leftAmount = 0;
    this.amountAll = {
      amount: {
        amountFrom: 0,
        amountTo: 0,
      },
    };
    this.settingsFilter = {};
    this.min = 'min';
    this.max = 'max';
  }
  public ngOnInit(): void {
    this.sliderActive = false;
    this.leftSpace = 0;
    this.leftAmount = this.filterMin;
    this.sumAmount = this.sliderNumber;
    this.rightAmount = this.sumAmount;
  }
  public ngAfterViewInit(): void {
    if (this.leftAmount !== this.rightAmount) {
      this.constIndentCircle = (this.elementControlCircle.nativeElement.offsetWidth /
        this.elementView.nativeElement.offsetWidth) * 100; // продумать правый отступ
    }

    if (typeof this.filterUrlParams !== 'undefined') {

      let partOfLengthMin = (this.filterUrlParams[this.min][0] - this.filterMin) / (this.sumAmount - this.filterMin);
      let partOfLengthMax = (this.filterUrlParams[this.max][0] - this.filterMin) / (this.sumAmount  - this.filterMin);

      if (this.filterUrlParams[this.min][0] < this.filterMin) {
        partOfLengthMin = 0;
      }

      if (this.filterUrlParams[this.min][0] < this.filterMin) {
        partOfLengthMin = 0;
      }
      if (this.filterUrlParams[this.min][0] > this.sumAmount) {
        partOfLengthMin = 1;
      }
      if (this.filterUrlParams[this.max][0] > this.sumAmount) {
        partOfLengthMax = 1;
      }

      if (this.filterUrlParams[this.min].length > 0 && this.filterUrlParams[this.max].length > 0 ) {
        this.leftAmount = this.filterUrlParams[this.min][0];
        this.rightAmount = this.filterUrlParams[this.max][0];
      }
      const positionSliderLeft = Math.round(partOfLengthMin * 100);
      const positionSliderRight = Math.round(partOfLengthMax * 100);
      if (this.filterUrlParams[this.min].length !== 0 && this.filterUrlParams[this.max].length !== 0) {
        this.leftSpace = positionSliderLeft;
        this.widthSlider = (this.positionSliderRight - positionSliderLeft) - (this.positionSliderRight - positionSliderRight);
        this.positionSliderLeft = positionSliderLeft;
        this.positionSliderRight = positionSliderRight;
      }
    }
    this.cdr.detectChanges();
  }
  public setDown(event: MouseEvent): void {
    const coefficient = (event.offsetX / this.elementView.nativeElement.offsetWidth);
    const positionSlider = Math.round( 100 * parseFloat(coefficient.toFixed(2)));
    if (positionSlider === this.positionSliderLeft || positionSlider <= this.positionSliderLeft + 8) {
      this.leftActivateSlider = true;
      this.rightActivateSlider = false;
    } else if (positionSlider === this.positionSliderRight || positionSlider  >= this.positionSliderRight - 8) {
      this.leftActivateSlider = false;
      this.rightActivateSlider = true;
    }
    if (this.positionSliderRight <= 8) {
      this.leftActivateSlider = false;
      this.rightActivateSlider = true;
    }
    this.sliderActive = true;
  }
  public setMouseMovePosition(event: MouseEvent): void {
    if (this.sliderActive) {
      this.setPositionCircle(event);
    }
  }

  public setPositionCircle(event: MouseEvent): void {
    const coefficient = event.offsetX / this.elementView.nativeElement.offsetWidth;
    let coefficientProportion =  coefficient; // parseFloat(coefficient.toFixed(2));
    if (parseFloat(coefficient.toFixed(2)) >= 0.99) {
      coefficientProportion = 1;
    }
    const positionSlider = Math.round( 100 * coefficientProportion);

    if (this.leftActivateSlider) {
      this.positionSliderLeft = positionSlider;
      this.leftSpace = positionSlider;
      this.widthSlider = this.positionSliderRight - positionSlider;
      if (this.widthSlider < 0) {
        this.leftActivateSlider = false;
        this.rightActivateSlider = true;
        this.positionSliderLeft = this.positionSliderRight;
      }
    } else if (this.rightActivateSlider) {
      if (this.widthSlider === 0 || this.widthSlider === 1) {
        this.leftActivateSlider = true;
        this.rightActivateSlider = false;
        this.positionSliderLeft = this.positionSliderRight;
      }
      this.positionSliderRight = positionSlider;
      this.widthSlider =  this.positionSliderRight - this.positionSliderLeft;
    }

    const leftAmountFloat = (this.sumAmount - this.filterMin) * (this.positionSliderLeft / 100);
    const rightAmountFloat = (this.sumAmount - this.filterMin) * (this.positionSliderRight / 100);

    this.leftAmount =  Math.round(leftAmountFloat) + this.filterMin;
    this.rightAmount = Math.round(rightAmountFloat) + this.filterMin;
    this.amountAll.amount.amountFrom = this.leftAmount;
    this.amountAll.amount.amountTo = this.rightAmount;
    this.fromToSum.emit(this.amountAll);
  }
  public cancelDown(): void {
    this.sliderActive = false;
  }
  public dragStart(event: Event): void {
    event.preventDefault();
  }
  public leftAmountInput(): void {

    if (this.leftAmount === null || this.leftAmount < 0) {
      this.leftAmount = 0;
    }

    let inputAmountLeft = Math.round((this.leftAmount - this.filterMin) / (this.sumAmount - this.filterMin) * 100);

    if (this.leftAmount < this.filterMin) {
      inputAmountLeft = 0;
    }

    if (inputAmountLeft < 0) {
      inputAmountLeft = 0;
    }
    if (inputAmountLeft > 100) {
      inputAmountLeft = 100;
    }
    this.positionSliderLeft = inputAmountLeft;
    this.widthSlider = this.positionSliderRight - inputAmountLeft;
    this.leftSpace = inputAmountLeft;
    if (this.positionSliderLeft > this.positionSliderRight) {
      this.positionSliderLeft = this.positionSliderRight;
    }
    if (this.leftAmount > this.rightAmount || this.leftAmount > this.sumAmount) {
      this.leftAmount = this.rightAmount;
      this.positionSliderLeft = this.positionSliderRight;
      this.widthSlider = 0;
    }
    this.amountAll.amount.amountFrom = this.leftAmount;
    this.amountAll.amount.amountTo = this.rightAmount;
    this.fromToSum.emit(this.amountAll);
  }
  public rightAmountInput(): void {
    if (this.rightAmount === null || this.rightAmount < 0) {
      this.rightAmount = 0;
    }

    let inputAmountRight = Math.round((this.rightAmount - this.filterMin) / (this.sumAmount - this.filterMin) * 100);

    if (this.rightAmount > this.sumAmount) {
      inputAmountRight = 100;
    }
    if (inputAmountRight < 0) {
      inputAmountRight = 0;
    }
    if (inputAmountRight > 100) {
      inputAmountRight = 100;
    }
    this.positionSliderRight  = inputAmountRight;
    this.widthSlider =  this.positionSliderRight - this.positionSliderLeft;
    if (this.positionSliderRight < this.positionSliderLeft) {
      this.positionSliderRight = this.positionSliderLeft;
    }
    if (this.rightAmount < this.leftAmount || this.rightAmount > this.sumAmount) {
      this.leftAmount = this.rightAmount;
      this.positionSliderLeft = this.positionSliderRight;
      this.widthSlider = 0;
    }
    this.amountAll.amount.amountFrom = this.leftAmount;
    this.amountAll.amount.amountTo = this.rightAmount;
    this.fromToSum.emit(this.amountAll);
  }
}
