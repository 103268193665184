import {Component, HostBinding, Injectable, OnInit} from '@angular/core';
import {ToolToken} from './refresh/tool-token';
import {BinderComponentsService} from './instructions/services/binder-components.service';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {InterfaceLoader} from './reducer/interfaces/interface-route.name';
import {Store} from '@ngrx/store';
// import {fadeStateTrigger} from '../shared/animation/fede.animation';

@Component({
    selector: 'start-system',
    templateUrl: './system-component.html',
   // animations: [fadeStateTrigger]
})
@Injectable()
export class SystemComponent implements  OnInit {
   // @HostBinding('@fade') a = true;
    public viewAuthSwitch: boolean;
    public load: boolean;
    private load$: Observable<any>;

    constructor(
      private token: ToolToken,
      private binderComponentsService: BinderComponentsService,
      private route: ActivatedRoute,
      private storeLoader: Store<InterfaceLoader>,
    ) {
      this.viewAuthSwitch = false;
      this.load = false;
      this.load$ = this.storeLoader.select('loader');
      this.load$.subscribe((load: InterfaceLoader) => {
        if (load) {
          this.load = load.loader ;
        }
      });
    }
    public ngOnInit(): void {
      this.token.startIntervalRefresh();
    }
    public viewAuth(): void {
        this.viewAuthSwitch = !this.viewAuthSwitch;
    }

    public onMousemove(e: Event): void {
      this.binderComponentsService.initEvent(e);
    }
}
