import { Action } from '@ngrx/store';

export enum routerNameActionsType {
  updatedAt = '[ROUTE] updated atName',
}

export class RouteNameAction implements Action {
  public readonly type = routerNameActionsType.updatedAt;
  constructor(public payload: string) {}
}
export type RoutesActions = RouteNameAction;
