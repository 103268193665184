import {Component, OnInit, Input, Injectable, OnDestroy, Output, EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';

import {Resolve, Router} from '@angular/router';
import {UsersService} from '../../instructions/services/users.service';
import {User} from '../../instructions/models/user.model';
import {fadeStateTrigger} from '../../constructions/animations/fade.animations';
import {Message} from '../../instructions/models/massage.model';
import {Tools} from '../../constructions/tools';

@Component({
    selector: 'registration-users',
    templateUrl: './registration.component.html',
    animations: [fadeStateTrigger],
})
export class RegistrationComponent implements OnInit {
    public form: FormGroup;
    public errorForm: User | User[];
    public checkError: boolean;
    public hiddenRegForm: boolean;
    @Output() public authUserReg = new EventEmitter<any>();
    public message: Message;
    private tools: Tools;

    constructor(
        private userService: UsersService,
        private router: Router,
        title: Title,
        meta: Meta,
    ) {
        title.setTitle('Регистрация');
        meta.addTags([
            { name: 'keywords', content: 'авторизация, данные' },
            { name: 'description', content: 'Страница для регистрации в системе' },
        ]);
        this.hiddenRegForm = false;
        this.tools = new Tools();
    }

    public ngOnInit(): void {
        this.message = new Message('danger', '');
        this.form = new FormGroup({
            email: new FormControl(
                null,
                [Validators.required],
                [
                    this.correctEmail.bind(this),
                    this.forbiddenEmails.bind(this),
                ],
            ), // , Validators.email
            password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
            name: new FormControl(null, [Validators.required]),
            agree: new FormControl(null, [Validators.requiredTrue]),
            birthday: new FormControl(null, [Validators.required]),
            surname: new FormControl(null, [Validators.required]),
        });
        this.checkError = false;
    }
    onSubmit() {
        const {name, surname, birthday, email, password, agree} = this.form.value;
        const user = new User(name, surname, birthday, email, password, agree);
        if (user.agree) {
            user.agree = 1;
        } else {
            user.agree = 0;
        }
        this.userService.createNewUser(user)
            .subscribe((data: any) => {
                /*this.router.navigate(['/login'], {
                    queryParams: {
                        nowCanLogin: true
                    }
                });*/
                this.errorForm = data;
                if (Object.keys(this.errorForm).length) {
                    this.checkError = true;
                }
                const reg = this.confirmedAuthInfo(data);
                if (reg) {
                    // this.showMessage({text: 'Отлично, теперь можно войти!', type: 'confirmed'});
                    this.tools.setCookie('login_email', data.user[0], 14);
                    this.tools.setCookie('login_password', data.user[1], 14);
                    this.authUserReg.emit(data);
                }
            });
    }
    confirmedAuthInfo(data: any) {
        const keysUser =  Object.keys(data);
        for (const item of keysUser) {
            if (data[item] !== 'no' && data.agree) {
                return false;
            }
        }
        return true;
    }
    correctEmail(control: FormControl): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userService.checkEmail(control.value)
                .subscribe((email: any) => {
                   if (email.correct !== 'yes') {
                       resolve({anCorrectEmail: true});
                   } else {
                       resolve(null);
                   }
                });
        });
    }

    public forbiddenEmails(control: FormControl): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userService.getUserData(control.value)
                .subscribe((user: User[]) => {
                    if (user.length > 0) {
                        resolve({forbiddenEmail: true});
                    } else {
                        resolve(null);
                    }
                    /*let checkMail = this.checkEmail(user, control);
                    if (checkMail) {
                        resolve({forbiddenEmail: true});
                    } else {
                        resolve(null);
                    }*/
                });
        });
    }

    private showMessage(message: Message): void {
        this.message = message;
        window.setTimeout(() => {
            this.message.text = '';
            this.hiddenRegForm = true;
        }, 5000);
    }
    /*public checkEmail(user: User[], control: FormControl) {
        for (let item of user) {
            if (item.email === control.value) {
                return true;
            }
        }
        return false;
    }*/
}
