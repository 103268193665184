import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {APP_BASE_HREF} from '@angular/common';
import {LoginComponent} from './auth/login/login.component';

const routes: Routes = [
  {path: 'admin', loadChildren: './admin/admin.module#AdminModule'},
  {path: 'login', component: LoginComponent},
  {path: '', loadChildren: './user/user.module#UserModule'},
];
@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
    })],
    exports: [RouterModule],
    providers: [{provide: APP_BASE_HREF, useValue : '' }],
})

export class SystemRoutingModule {}
