import {Component, Injectable, OnInit, ViewChild} from '@angular/core';
import {Activity} from '../../../instructions/enums/users';
import {ImpressionModel} from '../../../instructions/models/impression.model';
import {CartService} from '../cart.service';
import {Tools} from '../../../constructions/tools';
import {BinderComponentsService} from '../../../instructions/services/binder-components.service';
import {InterfaceModalSettings} from '../../../instructions/models/interface.modal.settings';

@Component({
  selector: 'app-cart-article',
  templateUrl: './cart.article.component.html',
})

@Injectable()
export class CartArticleComponent implements OnInit {
  // @ViewChild('inputCountControl') public formArticles;

  public countArticle: number;
  public articlesCard: ImpressionModel[];
  public siteUrl: string;
  public articlesCardData: ImpressionModel[];
  public totalSum: number;
  public inProgressOrder: boolean;
  private cartArrayStringify: string;
  private cartArray: string[];
  private activity: any; // ENUM
  private tools: Tools;
  private arrayCatCount: any[];
  private dataReceived: boolean;
  private customCard: any;
  private arrayCardArticleCount: {}[];
  private arrayCardArticleCountDefault: any;
  private flagCount: boolean;
  constructor(private cartService: CartService, private binderComponentsService: BinderComponentsService) {
    this.activity = Activity;
    const tools = new Tools();
    this.siteUrl =  tools.pathSite;
    this.arrayCatCount = [];
    this.articlesCard = [];
    this.dataReceived = false;
    this.articlesCardData = [];
    this.cartArray = [];
    this.customCard = {
      articleCard: [],
    };
    this.arrayCardArticleCount = [];
    this.totalSum = 0;
    this.flagCount = false;
    this.inProgressOrder = false;
  }

  public ngOnInit(): void {
    this.cartArrayStringify = localStorage.getItem(this.activity.userCart);
    this.getDefiniteListArticle(this.cartArrayStringify);
    this.cartArray = JSON.parse(this.cartArrayStringify);
    const getLocalStorage = localStorage.getItem(this.activity.confirmOrder);
    if (getLocalStorage !== null) {
      const userSettings = JSON.parse(getLocalStorage);
      this.inProgressOrder = userSettings.ordering;
    }
    this.binderComponentsService.orderingConfirm.subscribe((data) => {
      this.inProgressOrder = data.ordering;
    });
  }

  public countControl(event: Event, id: number): void {
    const elementInput: any | EventTarget = event.target;
    const countArticle = parseInt(elementInput.value, 10) ;
    if (!this.dataReceived) {
      this.getDefiniteListArticle(this.cartArrayStringify, id, countArticle);
      this.customCard = this.setCart(this.articlesCard);
      this.dataReceived = true;
    }

    const customCardDynamic = this.handlerCartCountArticle(this.customCard, countArticle, id, true);
    this.articlesCard = customCardDynamic.articleCard;
    this.cartArray.map((item: any) => {
      const intArticleId = parseInt(item.articleId, 10);
      if (intArticleId === id) {
        item.countArticle = countArticle;
      }
    });
    const cartArrayStringify = JSON.stringify(this.cartArray);
    this.localStoreCart(cartArrayStringify);
  }

  private getDefiniteListArticle(cardArray: string, id: number = 0, countArticle: number = 1): void {

    if (cardArray !== null ) {
      const arrayObjectsCart = JSON.parse(cardArray);
      const arrayIds = this.getIdsArrayFromArrayCardObj(arrayObjectsCart);
      const stringifyArrayIds = JSON.stringify(arrayIds);
      const formData = new FormData();
      formData.append(this.activity.userCart, stringifyArrayIds);
      this.cartService.cartArticles(formData).subscribe((data: ImpressionModel[]) => {
        this.countArticle = data.length;
        const customCard = this.setCart(data);

        const card = [];
        const customCardDynamic = this.handlerCartCountArticle(customCard, countArticle, id, false);

        this.articlesCard = customCardDynamic.articleCard;
        if (id === 0) {
          this.articlesCard.map((item: ImpressionModel, i) => {
            arrayObjectsCart.map((itemLocalStore) => {
              if (parseInt(itemLocalStore.articleId, 10) === item.id) {
                item.countArticle = parseInt(itemLocalStore.countArticle, 10);
              }
            });
            item.sum = item.countArticle * item.price;
            this.totalSum += item.sum;
          });
        }
      });
    }
  }

  public deleteCartArticle(articleId): void {
    this.cartArray.map((item: any, i: number) => {
      const articleIdNumber = parseInt(item.articleId, 10);
      if (articleIdNumber === articleId) {
        this.cartArray.splice(i, 1);
      }
    });

    this.articlesCard.map((item: ImpressionModel, i: number) => {
      if (item.id === articleId) {
        this.articlesCard.splice(i, 1);
      }
    });
    const cartArrayStringify = JSON.stringify(this.cartArray);

    this.localStoreCart(cartArrayStringify);
    const countCart = this.cartArray.length;
    this.binderComponentsService.initCardArticle(countCart);
  }
  public takeAwayCount(articleId): void {
    this.handlerCountArticle(articleId);
  }
  public addCount(articleId): void {
    this.handlerCountArticle(articleId, true);
  }
  public openOrdering(open: boolean): void {
    const width = 700;
    const height = 570;
    const settings: InterfaceModalSettings = {open, width, height};
    this.binderComponentsService.initOrdering(settings);
  }
  public confirmReceiving(): void {
    // localStorage.setItem(this.activity.userCart, cartArticles);
    localStorage.removeItem(this.activity.userCart);
    this.articlesCard = [];
    localStorage.removeItem(this.activity.confirmOrder);
    this.inProgressOrder = false;
    this.binderComponentsService.initReceivingConfirm();
  }
  private handlerCartCountArticle(articleCustomCard: any, countArticle: number, id: number, calculation: boolean = false): any {

    let i = 0;
    const cartArticles = articleCustomCard.articleCard;
    this.totalSum = 0;
    for (const cartArticle of cartArticles) {

      if (cartArticle.id === id) {
        cartArticle.countArticle = countArticle;
        cartArticles[i].sum = countArticle * cartArticles[i].price;
      } else {
        if (this.articlesCard.length > 0) {
          cartArticle.countArticle = this.articlesCard[i].countArticle;
        }
        cartArticles[i].sum = cartArticles[i].countArticle * cartArticles[i].price;
      }

      if (calculation) {
        this.flagCount = true;
      }
      if (this.flagCount) {
        this.totalSum += cartArticles[i].sum;
      }
      i++;
    }

    return {articleCard: cartArticles};
  }

  private setCart(data: ImpressionModel[]): any {
    return  {
      ...this.customCard,
      articleCard: data,
    };
  }
  private getIdsArrayFromArrayCardObj(idsArticlesCard): any { // Card?
    const idsArray = [];
    idsArticlesCard.map((item) => {
      idsArray.push(item.articleId);
    });
    return idsArray;
  }
  private localStoreCart(cartArticles): void {
    if (cartArticles !== null) {
      localStorage.setItem(this.activity.userCart, cartArticles);
    }
  }
  private handlerCountArticle(articleId, add: boolean = false): void {
    this.totalSum = 0;
    this.articlesCard.map((item: ImpressionModel) => {
      if (item.id === articleId) {
        if (add) {
          item.countArticle ++;
        } else {
          item.countArticle --;
        }
        this.handlerArticleMemory(articleId, item.countArticle);
      }
      item.sum = item.countArticle * item.price;
      this.totalSum += item.sum;
    });
  }
  private handlerArticleMemory(id: number, countArticle): void { // refactoring
    this.cartArray.map((item: any) => {
      const intArticleId = parseInt(item.articleId, 10);
      if (intArticleId === id) {
        item.countArticle = countArticle;
      }
    });
    const cartArrayStringify = JSON.stringify(this.cartArray);
    this.localStoreCart(cartArrayStringify);
  }
}
