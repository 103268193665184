import {
  Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';

@Component({
  selector: 'loader',
  template: `<span class="loader__test"></span>`,
})
export class LoaderComponent {
  // @ViewChild('button') public button: ElementRef<HTMLInputElement>;

  constructor() {}
}
