export class User {
    constructor(
        public name?: string,
        public surname?: string,
        public birthday?: string,
        public email?: string,
        public password?: string,
        public agree?: boolean | number,
        public id?: number,
        public phone?: number,
        public login?: string,
        public type?: string,
        public user?: [],
        public token?: string | null,
    ) {}
}
