export class AdminAuthService  {
    private userData = {};
    private isLogin: boolean;
    constructor() {
        this.isLogin = false;
    }
    public setUserData(data): void {
      this.userData =  data;
    }
    public getUserData(): any {
      return this.userData;
    }
}
