import {Injectable} from '@angular/core';
import {RefreshService} from './refresh.service';

@Injectable()
export class ToolToken {
  constructor(private usersService: RefreshService) {}

  public startIntervalRefresh(): void {
    const token = localStorage.getItem('token');
    this.tokenRefresh(token);
    setInterval(() => {
      if (token) {
        this.tokenRefresh(token);
      }
    },  1000 * 60 * 7);
  }
  private tokenRefresh(token: string): void {
    if (token) {
      this.usersService.tokenRefresh(token).subscribe((response) => {
        if (response.token) {
          localStorage.setItem('token', response.token);
        }
      });
    }
  }
}
