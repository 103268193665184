import { Action } from '@ngrx/store';
import {INIT_USER_CATEGORIES} from './user-categories.methods.types';

export enum countActionsType {
  updatedAt = '[COUNT] updated at',
  updatedAtName = '[COUNT] updated atName',
}

export class UserCategoriesMethodsAction implements Action {
  public readonly type = countActionsType.updatedAt;

  constructor(public payload: any) {}
}
export class UserCategoriesNameAction implements Action {
  public readonly type = countActionsType.updatedAtName;

  constructor(public payload: any) {}
}
export type MethodsActions = UserCategoriesMethodsAction
  | UserCategoriesNameAction;
